<template>
  <div class="text-center">
    <span v-if="showTimer">
      {{ $lang("RESENDCODEWAITMSG", [timerCount.toString()]) }}
    </span>
    <v-btn
      :loading="loading"
      :disabled="loading"
      v-else
      text
      @click="handleClick"
    >
      {{ $lang("ResendCode") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["duration"],
  data() {
    return {
      timerCount: 0,
      showTimer: true,
      loading: false,
    };
  },
  methods: {
    handleClick() {
      this.loading = true;
      this.$emit("clicked");
      setTimeout(() => {
        this.timerCount = this.duration;
        this.showTimer = true;
        this.loading = false;
      }, 3000);
    },
  },
  mounted() {
    this.timerCount = this.duration;
  },
  watch: {
    duration(val) {
      if (val > 0) {
        this.timerCount = val;
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          this.showTimer = true;
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.showTimer = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>