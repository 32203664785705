<template>
  <v-row class="justify-center align-center">
    <v-col cols="12" md="5">
      <v-img src="/artwork/verification.png" />
    </v-col>
    <v-col cols="12" md="5">
      <form @submit.prevent="doVerification">
        <div class="headline text-center mb-4">
          {{ $lang("AUTH:VERIFY_MOBILE_TITLE") }}
        </div>
        <v-divider v-if="authUser.mobile"></v-divider>
        <v-card-actions v-if="authUser.mobile">
          {{ $lang("Current") + " " + $lang("Mobile") }}
          <v-spacer></v-spacer>
          {{ authUser.mobile }}
        </v-card-actions>

        <v-divider v-if="authUser.temp_mobile"></v-divider>
        <v-card-actions v-if="authUser.temp_mobile">
          {{ $lang("New") + " " + $lang("Mobile") }}
          <v-spacer></v-spacer>
          {{ authUser.temp_mobile }}
        </v-card-actions>

        <v-divider></v-divider>
        <v-card-text class="text-center">
          {{ $lang("AUTH:VERIFY_MOBILE_HINT") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <v-text-field
            prepend-icon="mdi-lock-open"
            :label="$lang('AUTH:VERIFY_MOBILE:INPUT_TEXT_SALT-LABEL')"
            :hint="$lang('AUTH:VERIFY_MOBILE:INPUT_TEXT_SALT-HINT')"
            v-model="salt"
            :error-messages="errors && errors.token ? errors.token : []"
          ></v-text-field> -->
          <v-otp-input
            v-model="salt"
            :disabled="wait"
            @finish="onFinish"
            :error-messages="errors && errors.token ? errors.token : []"
          ></v-otp-input>
        </v-card-text>
        <v-card-actions class="mb-6">
          <v-btn block class="primary dark" type="submit">{{
            $lang("AUTH:VERIFY_MOBILE:BUTTON_SUBMIT-TEXT")
          }}</v-btn>
        </v-card-actions>
        <countdown-timer :duration="30" @clicked="resendCode" />
        <!-- <v-btn text @click="resendCode">{{
              $lang("AUTH:VERIFY_MOBILE:BUTTON_SEND_AGAIN-TEXT")
            }}</v-btn> -->
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import localforage from "@/plugins/localforage";
import { isEmpty } from "lodash";
import CountdownTimer from "../modules/CountdownTimer.vue";
export default {
  name: "verify-email",
  components: { CountdownTimer },
  data() {
    return {
      salt: "",
      wait: false,
    };
  },
  mounted() {
    this.check();
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
      authUser: "auth/user",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  watch: {
    authUser() {
      this.check();
    },
  },
  methods: {
    onFinish(rsp) {
      this.salt = rsp;
      setTimeout(() => {
        this.doVerification();
      }, 200);
    },

    check() {
      if (
        this.authUser &&
        !this.authUser.mobile &&
        !this.authUser.temp_mobile
      ) {
        this.$store.dispatch(
          "setAlert",
          {
            type: "error",
            message:
              "You dont have any mobile numbers to verify, add a number first.",
          },
          { root: true }
        );
        this.$router.replace({ name: "update-mobile" });
        return;
      }

      if (this.authUser.mobile_verified) {
        console.log("has verified mobile");
        // if (this.authUser.temp_mobile) {
        this.$router.replace({ name: "profile" });
        return;
        // }
      }
    },
    ...mapActions({
      verifyMobile: "auth/verifyMobile",
      resentMobileVerificationCode: "auth/resentMobileVerificationCode",
    }),
    resendCode() {
      if (!this.authUser || !this.authUser.mobile_verified) {
        return this.resentMobileVerificationCode({
          user: this.auth.user,
          context: this,
        });
      }
      this.$router.replace({ name: "update-mobile" });
    },
    afterSuccess() {
      localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }
          localforage.removeItem("intended");
          this.$router.replace(intended);
        })
        .catch(() => {
          this.$router.replace({ name: "home" });
        });
    },
    doVerification() {
      if (!this.salt || this.salt.trim().length < 1) {
        this.$store.dispatch(
          "setAlert",
          { type: "error", message: "Verification Code can not be empty." },
          { root: true }
        );
        return;
      }
      this.wait = true;
      this.verifyMobile(this.salt.trim())
        .then(() => {
          this.wait = false;
          // this.$router.replace({ name: "home" });
          this.afterSuccess();
        })
        .catch((e) => {
          this.wait = false;
        });
    },
  },
};
</script>


